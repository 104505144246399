<script lang="ts">
export default {
  name: 'app-card-regular'
}
</script>

<script lang="ts" setup>
const props = defineProps({
  img: {
    type: String
  },
  slug: {
    type: String
  },
  titulo: {
    type: String
  },
  fecha: {
    type: String
  },
  resumen: {
    type: String
  },
  alt: {
    type: String
  },

});

const slots = useSlots();

const getImage = computed(() => useAssets(`/assets/images/${props.img}`));
const hasSlot = (name: Readonly<string>): Boolean => !!slots[name];

</script>

<template lang="pug">
article.app-card-regular
  .app-card-regular__header
    img.img-fluid(:src="props.img",:alt="alt")
  .app-card-regular__content
    article.app-card-regular__body
      h2 {{ props.titulo }}
      p {{props.resumen}}
    article.app-card-regular__footer(v-if="hasSlot('footer')")
      slot(name="footer")
</template>

<style lang="scss">
@import 'index';
</style>